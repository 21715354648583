module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"citrus robotti","short_name":"citrus robotti","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"citrus-robotti","preview":false,"langs":["fi"],"pages":[{"type":"Homepage","match":"/","path":"/","component":"/opt/build/repo/src/templates/homepage.js","langs":["fi"]},{"type":"Robotpage","match":"/robot","path":"/","component":"/opt/build/repo/src/templates/robotpage.js","langs":["fi"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-45876755-4","head":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
